import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function showResponse(totalExpense) {
  return <p>Total Expense: {totalExpense}</p>;
}

function ExpenseAnalyzer() {
  const [initialExpense, setInitialExpense] = useState(0);
  const [timePeriod, setTimePeriod] = useState(1); // default time period is 1 year
  const [timeUnit, setTimeUnit] = useState("year");
  const [incrementType, setIncrementType] = useState("none");
  const [incrementAmount, setIncrementAmount] = useState(0);
  const [incrementPercentage, setIncrementPercentage] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);

  // function to calculate total expense
  function calculateTotalExpense() {
    let totalExpense = initialExpense;
    let timePeriodInDays = timePeriod;

    // convert time period to days
    if (timeUnit === "month") {
      timePeriodInDays *= 30;
    } else if (timeUnit === "year") {
      timePeriodInDays *= 365;
    }

    // calculate total expense based on increment type
    if (incrementType === "fixed") {
      totalExpense += incrementAmount * timePeriodInDays;
    } else if (incrementType === "percentage") {
      totalExpense += (initialExpense * incrementPercentage * timePeriodInDays) / 100;
    }

    setTotalExpense(totalExpense);
  }

  return (
    <Form>
      <Form.Group controlId="initialExpense">
        <Form.Label>Initial Expense:</Form.Label>
        <Form.Control type="number" value={initialExpense} onChange={(e) => setInitialExpense(Number(e.target.value))} />
      </Form.Group>

      <Form.Group controlId="timePeriod">
        <Form.Label>Time Period:</Form.Label>
        <Form.Control type="number" value={timePeriod} onChange={(e) => setTimePeriod(Number(e.target.value))} />
        <Form.Control
          as="select"
          value={timeUnit}
          onChange={(e) => setTimeUnit(e.target.value)}
        >
          <option value="day">Days</option>
          <option value="month">Months</option>
          <option value="year">Years</option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="incrementType">
        <Form.Label>Expense Increment Type:</Form.Label>
        <Form.Control
          as="select"
          value={incrementType}
          onChange={(e) => setIncrementType(e.target.value)}
        >
          <option value="none">No Increment</option>
          <option value="fixed">Fixed Amount</option>
          <option value="percentage">Percentage</option>
        </Form.Control>
      </Form.Group>

      {incrementType !== "none" && (
        <>
          {incrementType === "fixed" ? (
            <Form.Group controlId="incrementAmount">
              <Form.Label>Increment Amount:</Form.Label>
              <Form.Control
                type="number"
                value={incrementAmount}
                onChange={(e) => setIncrementAmount(Number(e.target.value))}
              />
            </Form.Group>
          ) : (
            <Form.Group controlId="incrementPercentage">
              <Form.Label>Increment Percentage:</Form.Label>
              <Form.Control
                type="number"
                value={incrementPercentage}
                onChange={(e) => setIncrementPercentage(Number(e.target.value))}
              />
            </Form.Group>
          )}
        </>
      )}

      <Button variant="primary" onClick={calculateTotalExpense}
        >
          Calculate Total Expense
        </Button>
        {totalExpense > 0 && showResponse(totalExpense)}
      </Form>
    );
}

export default ExpenseAnalyzer;

