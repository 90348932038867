//all components insert here:
import SimpleInterestCalculator from '../FunctionalComponents/SimpleInterest';
import CompoundInterestCalculator from '../FunctionalComponents/CompoundInterest';
import DepreciationCalculator from '../FunctionalComponents/DepreciationCalculator';
import InvestmentCalculatorAdvanced from '../FunctionalComponents/AdvancedInterestCalculator';
import ExpenseAnalyzer from '../FunctionalComponents/ExpenseCalculator';
import MoneyValueCalculator from '../FunctionalComponents/MoneyValueCalculator';

const blogs = [
    {
        "title": "Depreciation Calculator",
        "postDate": "2022-12-31",
        "imageLink": "images/blogcontents/computer.jpg",
        "shortIntro": "Calculate the depreciated value of your vehicle over a given number of years.",
        "btnName": "Depreciation Calculator",
        "contentName": DepreciationCalculator,
        "fullDesc": "This tool calculates the depreciated value of your vehicle based on the original value, number of years used, and depreciation rate.",
        "shortLink": "depreciation-calculator"
      },
      {
        "title": "Money Value Calculator",
        "postDate": "2023-05-08",
        "imageLink": "images/blogcontents/computer.jpg",
        "shortIntro": "Calculate the future value of money based on inflation rates. ",
        "btnName": "Calculate Future Value",
        "contentName": MoneyValueCalculator,
        "fullDesc": "This tool calculates the future value of money based on the inflation rate of a selected country. Input the initial value, number of years, and select a country to calculate the future value.",
        "shortLink": "money-value-calculator"
      },
      {"title": "Investment calculator",
      "postDate": "2022-12-31",
      "imageLink": "images/blogcontents/computer.jpg",
      "shortIntro": "Calculate your potential investment returns by projecting your daily earnings over a chosen period.",
      "btnName": "Investment Calculator",
      "contentName": InvestmentCalculatorAdvanced,
      "fullDesc": "This is the tool to calculate simple interest this is where",
      "shortLink": "simple-interest",
      } ,
      {
        "title": "Expense Calculator",
        "postDate": "2022-12-31",
        "imageLink": "images/blogcontents/computer.jpg",
        "shortIntro": "Find out what will be the total expense after certain days/months/years",
        "btnName": "Expense Calculator",
        "contentName": ExpenseAnalyzer,
        "fullDesc": "",
        "shortLink": "expense-calculator"
      }, 
      {"title": "Compound Interest",
      "postDate": "2022-12-31",
      "imageLink": "images/blogcontents/computer.jpg",
      "shortIntro": "Discover how much your investment can grow with compound interest by calculating the total amount you can earn over a given number of years.",
      "btnName": "Compound Interest",
      "contentName": CompoundInterestCalculator,
      "fullDesc": "This is the tool to calculate compound interest",
      "shortLink": "compound-interest",
      } ,     
      {"title": "Simple Interest",
      "postDate": "2022-12-31",
      "imageLink": "images/blogcontents/computer.jpg",
      "shortIntro": "Simply input your principal amount, interest rate, and investment period to see your potential returns.",
      "btnName": "Simple Interest",
      "contentName": SimpleInterestCalculator,
      "fullDesc": "This is the tool to calculate simple interest this is where",
      "shortLink": "simple-interest",
      }  

           
];

export default blogs;
