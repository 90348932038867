import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function SimpleInterestCalculator() {
  const [principal, setPrincipal] = useState(0);
  const [rate, setRate] = useState(0);
  const [time, setTime] = useState(0);
  const [result, setResult] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();

    const interest = (principal * rate * time) / 100;
    setResult(interest.toFixed(2));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formPrincipal">
        <Form.Label>Principal Amount</Form.Label>
        <Form.Control
          type="number"
          value={principal}
          onChange={(event) => setPrincipal(event.target.value)}
          placeholder="Enter Principal Amount"
        />
      </Form.Group>

      <Form.Group controlId="formRate">
        <Form.Label>Annual Interest Rate</Form.Label>
        <Form.Control
          type="number"
          value={rate}
          onChange={(event) => setRate(event.target.value)}
          placeholder="Enter Annual Interest Rate"
        />
      </Form.Group>

      <Form.Group controlId="formTime">
        <Form.Label>Time (in years)</Form.Label>
        <Form.Control
          type="number"
          value={time}
          onChange={(event) => setTime(event.target.value)}
          placeholder="Enter Time in years"
        />
      </Form.Group>

      <Form.Group controlId="formInterest">
        <Form.Label>Interest Amount</Form.Label>
        <Form.Control type="text" value={result.toLocaleString('en-IN')} readOnly />
      </Form.Group>

      <Button variant="primary" type="submit" style={{ marginTop: "10px" }}>
        Calculate Interest
      </Button>
    </Form>
  );
}

export default SimpleInterestCalculator;
