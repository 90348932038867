import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';


function calculateInvestment(amountStr, frequency, increaseType, increaseValueStr, durationStr, returnRateStr) {
    // Convert input strings to numbers
    const amount = isNaN(amountStr) ? 0 : Number(amountStr);
    const increaseValue = isNaN(increaseValueStr) ? 0 : Number(increaseValueStr);
    const duration = isNaN(durationStr) ? 0 : Number(durationStr);
    const returnRate = isNaN(returnRateStr) ? 0 : Number(returnRateStr);
  
    // Check if inputs are valid numbers
    if (isNaN(amount) || isNaN(increaseValue) || isNaN(duration) || isNaN(returnRate)) {
      return "Invalid input";
    }
  
    let totalAmount = amount;
    let rate = returnRate / 100;
    let increment = 0;
    let daysInYear = 365;
  
    if (frequency === 'daily') {
      increment = 1;
      daysInYear = 365;
    } else if (frequency === 'monthly') {
      increment = 30;
      daysInYear = 12;
    } else {
      increment = 365;
      daysInYear = 1;
    }
  
    for (let i = 0; i < duration * increment; i++) {
      let investmentIncrease = increaseType === 'percent' ? totalAmount * (increaseValue / 100) : increaseValue;
      totalAmount += investmentIncrease;
      let interest = totalAmount * (rate / daysInYear);
      totalAmount += interest;
    }
  
    return totalAmount.toFixed(2);
  }
  
  


function InvestmentCalculatorAdvanced() {
    const [amount, setAmount] = useState(0);
    const [frequency, setFrequency] = useState("daily");
    const [increaseType, setIncreaseType] = useState("percent");
    const [increaseValue, setIncreaseValue] = useState(0);
    const [duration, setDuration] = useState(1);
    const [returnRate, setReturnRate] = useState(0);
    const [result, setResult] = useState(0);
  
    const handleCalculate = (e) => {
      e.preventDefault();
      const investment = calculateInvestment(
        amount,
        frequency,
        increaseType,
        increaseValue,
        duration,
        returnRate
      );
      setResult(investment);
    };
  
    return (
      <Form onSubmit={handleCalculate}>
        <Form.Group className="mb-3">
          <Form.Label>Initial Investment Amount</Form.Label>
          <Form.Control
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            required
          />
        </Form.Group>
  
        <Form.Group className="mb-3">
          <Form.Label>Investment Frequency</Form.Label>
          <Form.Select
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            required
          >
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </Form.Select>
        </Form.Group>
  
        <Form.Group className="mb-3">
          <Form.Label>Investment Increase Type</Form.Label>
          <Form.Select
            value={increaseType}
            onChange={(e) => setIncreaseType(e.target.value)}
            required
          >
            <option value="percent">Percentage Increase</option>
            <option value="amount">Fixed Amount Increase</option>
          </Form.Select>
        </Form.Group>
  
        <Form.Group className="mb-3">
          <Form.Label>Investment Increase Value</Form.Label>
          <Form.Control
            type="number"
            value={increaseValue}
            onChange={(e) => setIncreaseValue(e.target.value)}
            placeholder="Enter increase value"
            required
          />
        </Form.Group>
  
        <Form.Group className="mb-3">
          <Form.Label>Investment Duration</Form.Label>
          <Form.Control
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            placeholder="Enter duration in years"
            required
          />
        </Form.Group>
  
        <Form.Group className="mb-3">
          <Form.Label>Expected Annual Return Rate (%)</Form.Label>
          <Form.Control
            type="number"
            value={returnRate}
            onChange={(e) => setReturnRate(e.target.value)}
            placeholder="Enter expected return rate"
            required
          />
        </Form.Group>
  
        <Button variant="primary" type="submit">
          Calculate Investment
        </Button>
  
        {result !== 0 && (
          <div className="mt-3">
            <p>You will have: {result.toLocaleString('en-IN')}</p>
          </div>
        )}
      </Form>
    );
  }

  

export default InvestmentCalculatorAdvanced;