import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const DepreciationCalculator = () => {
    const [originalValue, setOriginalValue] = useState(0);
    const [yearsUsed, setYearsUsed] = useState(0);
    const [depreciationRate, setDepreciationRate] = useState(0);
    const [depreciatedValue, setDepreciatedValue] = useState(0);
    const [vehicleType, setVehicleType] = useState('car');
  
    const calculateDepreciation = () => {
      //
      const depreciatedValue = originalValue  * depreciationRate * 0.01 * yearsUsed;
      setDepreciatedValue(depreciatedValue);
    }

  return (
    <div className="container mt-5">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Depreciation Calculator</Form.Label>
          <Form.Select
            value={vehicleType}
            onChange={(e) => setVehicleType(e.target.value)}
            required
          >
            <option value="Bike">Bike</option>
            <option value="Scooter">Scooter</option>
            <option value="Car">Car</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="originalValue">
          <Form.Label>Original Value:</Form.Label>
          <Form.Control type="number" placeholder="Enter original value" value={originalValue} onChange={(e) => setOriginalValue(parseInt(e.target.value))} />
        </Form.Group>

        <Form.Group controlId="yearsUsed">
          <Form.Label>Years Used:</Form.Label>
          <Form.Control type="number" placeholder="Enter number of years used" value={yearsUsed} onChange={(e) => setYearsUsed(parseInt(e.target.value))} />
        </Form.Group>
        <Form.Group controlId="depreciationRate">
          <Form.Label>Depreciation Rate:</Form.Label>
          <Form.Control type="number" min="0" max="100" step="0.1" placeholder="Enter depreciation rate as a decimal" value={depreciationRate} onChange={(e) => setDepreciationRate(parseFloat(e.target.value))} />
        </Form.Group>

        <Button variant="primary" style={{ marginTop: '10px'}}onClick={calculateDepreciation}>Calculate Depreciation</Button>
      </Form>

      {depreciatedValue > 0 &&
        <div className="mt-3">
          <p>Depreciated Value for {vehicleType}: {depreciatedValue.toLocaleString('en-IN')}</p>
        </div>
      }
    </div>
  );
}

export default DepreciationCalculator;
