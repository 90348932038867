import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function MoneyValueCalculator() {
  const [initialValue, setInitialValue] = useState('');
  const [years, setYears] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [futureValue, setFutureValue] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Convert the initial value to a number
    const initialValueNumber = parseFloat(initialValue);

    // Convert the number of years to an integer
    const yearsInt = parseInt(years);

    // Calculate the future value based on the selected country's inflation rate
    let inflationRate;
    switch (selectedCountry) {
      case 'Nepal':
        inflationRate = 0.0626;
        break;
      case 'USA':
        inflationRate = 0.0805;
        break;
      case 'India':
        inflationRate = 0.0689;
        break;
      case 'China':
        inflationRate = 0.0217;
        break;
      default:
        inflationRate = 0;
        break;
    }
    const futureValueNumber = initialValueNumber * Math.pow(1 + inflationRate, yearsInt);

    // Set the future value in the state
    setFutureValue(futureValueNumber.toFixed(2));
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="initialValue">
              <Form.Label>Initial Value</Form.Label>
              <Form.Control type="number" step="0.01" value={initialValue} onChange={(event) => setInitialValue(event.target.value)} />
            </Form.Group>
            <Form.Group controlId="years">
              <Form.Label>Years</Form.Label>
              <Form.Control type="number" value={years} onChange={(event) => setYears(event.target.value)} />
            </Form.Group>
            <Form.Group controlId="selectedCountry">
              <Form.Label>Select a country</Form.Label>
              <Form.Control as="select" value={selectedCountry} onChange={(event) => setSelectedCountry(event.target.value)}>
                <option value="">Select a country</option>
                <option value="Nepal">Nepal - 6.26%</option>
                <option value="USA">USA - 8.05%</option>
                <option value="India">India - 6.89%</option>
                <option value="China">China - 2.17%</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" style={{ marginTop: '10px'}}>
              Calculate
            </Button>
          </Form>
        </Col>
      </Row>
      {futureValue !== '' && (
        <Row>
          <Col>
            <p>In just {years} year/s, the value of {initialValue} will increase to {futureValue.toLocaleString('en-IN')} in {selectedCountry}.</p>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default MoneyValueCalculator;
