import React from 'react';
import { FaFacebook, FaTiktok, FaInstagram } from 'react-icons/fa';
import './Footer.css'; // Import CSS for styling

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <h3>Contact Us</h3>
          <p>Tooleso</p>
          <p>Pokhara</p>
          <p>Phone: (+977) </p>
          <p>The all we need is here.</p>
          <div className="social-icons">
            <a href="https://facebook.com/tooleso" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="icon" />
            </a>
            <a href="https://www.tiktok.com/@tools.eso" target="_blank" rel="noopener noreferrer">
              <FaTiktok className="icon" />
            </a>
            <a href="https://www.instagram.com/tools.eso" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="icon" />
            </a>
          </div>
          <p>© {new Date().getFullYear()} Tooleso. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
