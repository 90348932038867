import React, { useState } from 'react';
import blogs from '../Blog/blogs';
import CustomModal from '../ReusableComponents/formInput';
import './BlogListView.css'; // Import CSS for styling

function BlogListView() {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredBlogs = blogs.filter(blog =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div id="tools" className="overflow-y-scroll overflow-x-hidden pb-4 pt-2 px-4 min-h-screen flex flex-col">
      <div id="search" className="w-full mb-4 flex justify-center">
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          className="rounded-lg bg-white px-4 py-2 shadow-md focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
      </div>


      {filteredBlogs.length > 0 ? (
        <div className="grid gap-4"> {/* Grid container with gap */}
          {filteredBlogs.map((blog, idx) => (
            <div key={idx} className="blog-card"> {/* Individual card */}
              <div className="grid grid-cols-1 md:grid-cols-2"> {/* Grid for image and content */}
                <div className="image-container relative">
                  <a href="#">
                    <img
                      src={blog.imageLink}
                      alt={blog.title}
                      className="object-cover w-full h-full rounded-t-lg"
                    />
                  </a>
                </div>
                <div className="content-container p-4">
                  <a href="#">
                    <h3 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                      {blog.title}
                    </h3>
                  </a>
                  <p className="mb-3 text-sm text-gray-700 dark:text-gray-400">
                    {blog.shortIntro}
                  </p>
                  <CustomModal
                    title={blog.title}
                    buttonName={blog.btnName}
                    contentName={blog.contentName}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-1/2 text-2xl">
          Sorry! Nothing found
        </div>
      )}
    </div>
  );
}

export default BlogListView;
