import 'bootstrap/dist/css/bootstrap.min.css';
import NavBarView from "./Components/TabBar";
import BlogListView from "./Components/BlogListView";
import Footer from './Components/Footer';

function App() {
  return (
    <div className="main-layout">

      <header>
        <NavBarView />
      </header>
      
      <BlogListView />
      <Footer />
 
    </div>
  );
}

export default App;
