import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function NavBarView() {
  return (
    <>
    <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Tooleso: The all we need</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto" style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="#search">Search</Nav.Link>
             <Nav.Link href="https://game.thesmartaid.com">Play Car Game in web</Nav.Link>
            <Nav.Link href="#tools">Tools</Nav.Link>
            <Nav.Link href="#contact" smooth={true} duration={500}>Contact</Nav.Link>
            <Nav.Link href="https://blog.thesmartaid.com">Blog</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default NavBarView;
